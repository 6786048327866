import React from "react";
import PropTypes from "prop-types";
import { Row, Col } from "reactstrap"; // Replace with your UI library if needed
import CardComponent from "./CardComponent"; // Import your CardComponent

/**
 * A reusable grid component for displaying a list of cards.
 *
 * @param {Object} props - The component props.
 * @param {Array} props.data - An array of items to display as cards.
 * @param {string} props.darkModeClass - CSS class for dark mode (optional).
 * @param {string} props.apiEndpoint - The API endpoint (optional).
 */
function CardGridComponent({ data, darkModeClass, apiEndpoint, userInfo, isLoading, isAuthenticated, user }) {
    const isObject = (variable) => {
        return typeof variable === 'object' && variable !== null && !Array.isArray(variable);
    };

// Use this function to ensure data is always an array
    const dataArray = isObject(data) ? Object.values(data) : data;
   let ab = isObject(data) ? true : false
    // console.log(ab)


    return (
        <Row className={darkModeClass}>
            {dataArray.map((item, index) => (

                <Col key={index} xs="12" sm="6" md="4" lg="3" className="mb-4">
                    <CardComponent
                        item={item}
                        darkModeClass={darkModeClass}
                        apiEndpoint={apiEndpoint}
                        isLoading={isLoading}
                        isAuthenticated={isAuthenticated}
                        user={user}
                        userInfo={userInfo}

                    />
                </Col>
            ))}
        </Row>
    );
}

CardGridComponent.propTypes = {
    data: PropTypes.array.isRequired,
    darkModeClass: PropTypes.string,
    apiEndpoint: PropTypes.string,
};

export default CardGridComponent;


