// src/components/ErrorModalMessage.js
import React from 'react';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';

const ErrorModalMessage = ({ isDarkMode, title, message, isOpen, toggle }) => {
    return (
        <Modal isOpen={isOpen} toggle={toggle} aria-labelledby="modalTitle" aria-describedby="modalDescription">
            {title && <ModalHeader toggle={toggle} id="modalTitle">{title}</ModalHeader>}

             <ModalBody id="modalDescription">
                {message}
            </ModalBody>
        </Modal>
    );
};

export default ErrorModalMessage;
