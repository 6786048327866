// src/components/NavbarComponent.js
import React, { useState } from "react";
import {
    Collapse,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Nav,
    Navbar,
    NavbarBrand,
    NavbarToggler,
    NavItem,
    NavLink,
    UncontrolledDropdown,
} from "reactstrap";
import { useAuth0 } from "@auth0/auth0-react";
import LogoutButton from "./LogoutButton";
import LoginButton from "./LoginButton";
import "./NavbarComponent.css";
import SearchList from "./searchList";


const NavbarComponent = ({ onSearchClick, onDarkModeToggle, setApiEndpoint, darkModeClass, setIsProfileModalOpen }) => {
    const { isAuthenticated } = useAuth0();
    const [isOpen, setIsOpen] = useState(false);
    const navbarClass = darkModeClass.replace("light", "secondary");
    // const navbarColor = !isDarkMode ? "light" : "dark";

    const toggle = () => setIsOpen(!isOpen);

    return (
        <Navbar
            // color={navbarColor}
            className={"navbar " + navbarClass}
            expand="md"
        >
            <NavbarBrand href="/" className={navbarClass}>
                MyApp
            </NavbarBrand>
            <NavbarToggler onClick={toggle} />
            <Nav className={navbarClass + "ml-auto"} navbar>
                <Collapse isOpen={isOpen} navbar>
                    <NavItem>
                        {isAuthenticated ? <LogoutButton darkModeClass={darkModeClass} /> : <LoginButton darkModeClass={darkModeClass} />}
                    </NavItem>

                    {/*<SearchList navbarClass={navbarClass} setApiEndpoint={setApiEndpoint}/>*/}

                    <UncontrolledDropdown nav inNavbar className={navbarClass}>
                        <DropdownToggle nav caret className={navbarClass}>
                            Popular
                        </DropdownToggle>
                        <DropdownMenu right className={navbarClass}>
                            <DropdownItem onClick={() => setApiEndpoint("/topAll")} className={navbarClass}>
                                Top Anime
                            </DropdownItem>
                            <DropdownItem onClick={() => setApiEndpoint("/topAiring")} className={navbarClass}>
                                Top Currently Airing
                            </DropdownItem>
                            <DropdownItem onClick={() => setApiEndpoint("/topMovies")} className={navbarClass}>
                                Top Films
                            </DropdownItem>
                        </DropdownMenu>
                    </UncontrolledDropdown>
                    <NavItem>
                        <NavLink href="#" onClick={() => onSearchClick()} className={navbarClass}>
                            Search
                        </NavLink>
                    </NavItem>
                    {isAuthenticated && (
                        <NavItem>
                            <NavLink href="#" onClick={() => setIsProfileModalOpen(true)} className={navbarClass}>
                                Account Management
                            </NavLink>
                        </NavItem>
                    )}
                    <NavItem>
                        <NavLink href="#" className={navbarClass} onClick={onDarkModeToggle}>
                            Dark Mode
                        </NavLink>
                    </NavItem>
                </Collapse>
            </Nav>
        </Navbar>
    );
};

export default NavbarComponent;
