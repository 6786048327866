import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Button } from 'reactstrap';

const LoginButton = ({darkModeClass}) => {
    const { loginWithRedirect } = useAuth0();
    const colorChoice = darkModeClass.replace(" text-bg-dark", "secondary").replace(" text-bg-light", "secondary");
    console.log(window.location.origin + '/')


    return (

        <Button className={colorChoice} onClick={() => loginWithRedirect({ redirectUri: window.location.origin + '/' })}>
            Login/Register
        </Button>
    );
};

export default LoginButton;


