import React, { useState } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Input, FormGroup, Label, Form } from 'reactstrap';
import { useAuth0 } from '@auth0/auth0-react';

const SearchModal = ({ isOpen, toggle, setData }) => {
    const [searchTerm, setSearchTerm] = useState('');
    const { isAuthenticated } = useAuth0();

    const handleSearch = async () => {
        const baseUrl = process.env.REACT_APP_API_URL ; // Fallback to localhost if REACT_APP_API_URL is not set
        const url = isAuthenticated ? `${baseUrl}/searchAnime?searchquery=${encodeURIComponent(searchTerm)}` : `${baseUrl}/search2/${encodeURIComponent(searchTerm)}`;

        try {
            const response = await fetch(url, {
                method: 'GET',
                headers: { 'Content-Type': 'application/json' },
            });

            if (!response.ok) {
                throw new Error('Search failed');
            }

            const results = await response.json();
            setData(results);
            console.log(results);
        } catch (error) {
            console.error('Error:', error);
        }
    };

    return (
        <Modal isOpen={isOpen} toggle={toggle}>
            <ModalHeader toggle={toggle}>Search</ModalHeader>
            <ModalBody>
                <Form>
                    <FormGroup>
                        <Label for="searchTerm">Search Term</Label>
                        <Input
                            type="text"
                            name="searchTerm"
                            id="searchTerm"
                            placeholder="Enter search term"
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                        />
                    </FormGroup>
                </Form>
            </ModalBody>
            <ModalFooter>
                <Button color="primary" onClick={handleSearch}>Search</Button>{' '}
                <Button color="secondary" onClick={toggle}>Cancel</Button>
            </ModalFooter>
        </Modal>
    );
};

export default SearchModal;
