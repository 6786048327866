// src/components/HomePage.js
import React, { useState, useEffect } from "react";
import { Container, Row, Col, Card, CardImg, CardBody, CardTitle, CardText } from "reactstrap";
import { useAuth0 } from "@auth0/auth0-react";
import CardComponent from "./CardComponent";
import NavbarComponent from "./NavbarComponent";
import ErrorModalMessage from "./ErrorModalMessage";
import CardGridComponent from "./CardGridComponent"; // Import the CardGridComponent
import SearchModal from "./SearchModal";
import ProfileInfo from "./ProfileInfo";

const HomePage = ({ apiEndpoint, onDarkModeToggle, setApiEndpoint, choosePage, userEmail, setPassUpDarkMode }) => {
    const [isDarkMode, setIsDarkMode] = useState(false); // State to hold search results
    const [darkModeClass, setDarkModeClass] = useState("text-bg-light"); // State to hold search results
    const [data, setData] = useState([]);

    const [isSearchModalOpen, setIsSearchModalOpen] = useState(false);
    const [isProfileModalOpen, setIsProfileModalOpen] = useState(false);
    const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState("Error"); // State to hold search results
    const [searchTerm, setSearchTerm] = useState("");
    const [searchType, setSearchType] = useState("search"); // 'search' or 'searchUser'
    const [userInfo, setUserInfo] = useState(null);
    const { user, isAuthenticated, isLoading } = useAuth0();
    const [requestedComments, setRequestedComments ] = useState([]);
    const [requestedSearches, setRequestedSearches ] = useState([]);
    const [newComment, setNewComment] = useState("");



    useEffect(() => {
        let storedDarkModePref = localStorage.getItem("isDarkMode") === "true";
        // if (storedDarkModePref !== isDarkMode) {
        handleDarkModeToggle(true, storedDarkModePref);
        // }
        fetchUserInfo(); // Fetch user info on first load
    }, []);

    useEffect(() => {
        // console.log("Is Authenticated: ", isAuthenticated);
        fetchData(); // go get the main data based on which page we're on
        // console.log("Is Authenticated: ", isAuthenticated);
    }, [apiEndpoint, isAuthenticated]); // Include isAuthenticated in dependencies

    const onSearchClick = () => {
        console.log("handleSearch Clicked");
        if (isAuthenticated) {
            setIsSearchModalOpen(true);
            console.log("handleSearch yesAuth");
        } else {
            setErrorMessage("Please first Register or Login");
            setIsErrorModalOpen(true); // Open the modal if not authenticated
        }
    };

    /**
     * Handles the submission of a search request.
     * @param {string} searchTerm - The search term to be queried.
     * @param {string} username - The username of the user performing the search.
     * @param {boolean} isAuthenticated - (Optional) Indicates if the user is authenticated.
     */
    const handleSearchSubmit = (searchTerm, username, isAuthenticated = false) => {
        fetch(process.env.REACT_APP_API_URL + "/search", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({ searchQuery: searchTerm, username, isAuthenticated }),
        })
            .then((response) => response.json())
            .then((data) => {
                if (data.error) {
                    throw new Error(data.error);
                }
                setData(data.searchResults); // Assuming 'setData' updates the component state with the results
            })
            .catch((error) => {
                console.error("Search error:", error);
                let errorMessage = "Search error: " + error.toString();
                setErrorMessage(errorMessage);
                setIsErrorModalOpen(true); // Assuming this shows an error modal
            });
    };

    const closeModal = () => {
        setIsErrorModalOpen(false); // Function to close the modal
        setIsSearchModalOpen(false); // Function to close the modal
    };

    const apiTitles = {
        "/topAll": "Top Anime",
        "/topMovies": "Top Movies",
        "/topAiring": "Top Currently Airing",
    };

    const announceSelf = async (email) => {
        const isDarkMode = localStorage.getItem("isDarkMode") === "true"; // Assuming dark mode preference is stored in localStorage

        try {
            const response = await fetch(process.env.REACT_APP_API_URL + "/announceSelf", {
                // Adjust the URL as needed
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({ email, darkModePreferences: isDarkMode }),
            });

            const data = await response.json();
            // console.log('User info:', data);
            return data;
        } catch (error) {
            console.error("Error:", error);
        }
    };

    const fetchData = async () => {
        // calls desired page based on button clicked
        try {
            const response = await fetch(process.env.REACT_APP_API_URL + `${apiEndpoint}`);
            if (!response.ok) {
                throw new Error("Network response was not ok");
            }
            const data = await response.json();
            setData(data);
        } catch (error) {
            console.error("Fetch error:", error);
        }
    };

    const fetchUserInfo = async () => {
        try {
            if (isAuthenticated && user) {
                let data2 = await announceSelf(user.email, isDarkMode);
                console.log("User info:", data2);

                let processedData = processData(data2);
                // console.log(processedData)

                // Update the UI based on the fetched preference
                let storedDarkModePref = localStorage.getItem("isDarkMode") === "true";
                if (storedDarkModePref !== processedData.Darkmode) {
                    handleDarkModeToggle(true, processedData.Darkmode);
                }
                setUserInfo(processedData);
            }
        } catch (error) {
            console.error("Error fetching user info:", error);
        }
    };

    const processData = (data) => {
        return {
            User: data.user.email,
            Darkmode: data.preferences.darkMode,
            Searches: data.searches,
            Comments: data.comments,
        };
    };

    const handleDarkModeToggle = (partial, desired = null) => {
        if (desired != null) {
            setIsDarkMode(desired);
            setDarkModeClass(desired ? " text-bg-light" : " text-bg-dark");
            return;
        }
        setIsDarkMode(!isDarkMode);
        setDarkModeClass(isDarkMode ? " text-bg-light" : " text-bg-dark");
        setPassUpDarkMode(darkModeClass);
        if (!partial) {
            localStorage.setItem("isDarkMode", isDarkMode.toString());

            fetchUserInfo();
        }
    };

    const handleDarkModeToggleSettings = () => {
        let isDarkModeChoice = localStorage.getItem("isDarkMode") === "true";
        let newChoice = !isDarkModeChoice;

        localStorage.setItem("isDarkMode", newChoice.toString());
        fetchUserInfo(); // Sends the new preference to the server
        handleDarkModeToggle(true, newChoice);
    };

    return (
        <div className={darkModeClass}>
            <NavbarComponent
                onDarkModeToggle={handleDarkModeToggle}
                onSearchClick={onSearchClick}
                setApiEndpoint={setApiEndpoint}
                isDarkMode={isDarkMode}
                darkModeClass={darkModeClass}
                userEmail={userEmail}
                setIsProfileModalOpen={setIsProfileModalOpen}
            />
            <Container className={darkModeClass}>
                <h1> {apiTitles[apiEndpoint]}</h1>
                <CardGridComponent
                    data={data}
                    darkModeClass={darkModeClass}
                    apiEndpoint={apiEndpoint}
                    user={user}
                    userInfo={userInfo}
                    newComment={newComment}
                    setNewComment={setNewComment}
                />
                <SearchModal
                    isOpen={isSearchModalOpen}
                    toggle={closeModal}
                    setData={setData}
                    searchTerm={searchTerm}
                    searchType={searchType}
                    setSearchType={setSearchType}
                    setSearchTerm={setSearchTerm}
                    setIsSearchModalOpen={setIsSearchModalOpen}
                    username={userInfo}
                />
                <ErrorModalMessage isDarkMode={isDarkMode} message={errorMessage} isOpen={isErrorModalOpen} toggle={closeModal} />
                <ProfileInfo
                    isLoading={isLoading}
                    isAuthenticated={isAuthenticated}
                    userInfo={user}
                    userInfo={userInfo}
                    fetchUserInfo={fetchUserInfo}
                    setUserInfo={setUserInfo}
                    isDarkMode={(userInfo && userInfo.darkMode) || isDarkMode}
                    announceSelf={announceSelf}
                    isProfileModalOpen={isProfileModalOpen}
                    setIsProfileModalOpen={setIsProfileModalOpen}
                    isDarkMode={isDarkMode}
                    handleDarkModeToggleSettings={handleDarkModeToggleSettings}
                    setRequestedComments={setRequestedComments}
                    setRequestedSearches={setRequestedSearches}
                    requestedSearches={requestedSearches}
                    requestedComments={requestedComments}
                />
            </Container>
        </div>
    );
};

export default HomePage;
