import React, { useState, useEffect } from "react";
import { Card, CardImg, CardBody, CardTitle, CardText, Button, Input, FormGroup } from "reactstrap";
import axios from "axios";
import { useAuth0 } from "@auth0/auth0-react";

const CardComponent = ({ item, darkModeClass, apiEndpoint, userInfo, isLoading, user, newComment, setNewComment}) => {
    const [isExpanded, setIsExpanded] = useState(false);
    const [comment, setComment] = useState("");
    const { isAuthenticated } = useAuth0();

    // console.log(item)


    const toggleExpanded = () => {
        setIsExpanded(!isExpanded);
    };
    let userName = userInfo && userInfo.user;
    if (userName) {
        userName = userInfo.User;
    }
    if (userName) {
        userName = userInfo.User.email;
    }

    useEffect(() => {
        // console.log("userInfo, ", userInfo)
        if (userInfo) {

            axios
                .get(process.env.REACT_APP_API_URL + `/getComment`, { params: { username: userInfo.User, title: item.title } })
                .then((response) => {
                    setComment(response.data || "No comment found");
                })
                .catch((error) => console.error("Error fetching comment:", error));
        }
    }, [isLoading, isAuthenticated, user, userInfo]);

    const handleCommentSubmit = () => {
        console.log("handleCommentSubmit");
        console.log("isAuthenticated", isAuthenticated);
        isAuthenticated &&
        axios
            .post(process.env.REACT_APP_API_URL + "/addComment", {
                username: userInfo.User,
                title: item.title,
                content: newComment,
            })
            .then((response) => {
                setComment(newComment);
                setNewComment("");
            })
            .catch((error) => console.error("Error adding comment:", error));
    };

    return (
        <Card>
            <CardImg
                top
                width="100%"
                src={item.picture_url}
                alt={`Cover image of ${item.title}`}
                aria-label={`Cover image of ${item.title}`}
            />
            <CardBody className={darkModeClass}>
                <CardTitle tag="h5">{item.title}</CardTitle>

                {item.description && <CardText>{item.description}</CardText>}
                {item.rank && <CardText>Rank: {item.rank}</CardText>}
                {item.score && <CardText>Score: {item.score}</CardText>}

                {isExpanded && (
                    <>
                        {item.type && <CardText>Type: {item.type}</CardText>}
                        {item.aired_on && <CardText>Aired: {item.aired_on}</CardText>}
                        {item.members && <CardText>Members: {item.members}</CardText>}
                        {item.myanimelist_url && (
                            <CardText>
                                <a href={item.myanimelist_url} target="_blank" rel="noopener noreferrer">
                                    More on MyAnimeList
                                </a>
                            </CardText>
                        )}
                        {item.myanimelist_id && <CardText>ID: {item.myanimelist_id}</CardText>}
                        <CardText>Comment: {comment}</CardText>
                        <FormGroup>
                            <Input
                                type="textarea"
                                value={newComment}
                                onChange={(e) => setNewComment(e.target.value)}
                                placeholder="Write your comment here"
                            />
                            <Button color="secondary" style={{"margin":"6px 2px 2px 2px"}} onClick={handleCommentSubmit}>
                                Submit Comment
                            </Button>
                        </FormGroup>
                    </>
                )}

                <Button color="primary" onClick={toggleExpanded}>
                    {isExpanded ? "Less" : "More"}
                </Button>
            </CardBody>
        </Card>
    );
};

export default CardComponent;
