import React, { useState, useEffect } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";

const ProfileInfo = ({

                         isProfileModalOpen,
                         setIsProfileModalOpen,
                         isDarkMode,
                         setIsSearchModalOpen,
                         setSearchType,
                         setSearchTerm,
                         announceSelf,
                         userInfo,
                         setUserInfo,
                         fetchUserInfo,
                         isLoading,
                         isAuthenticated,
                         user,
                         handleDarkModeToggleSettings,
                         setRequestedComments,
                         setRequestedSearches,
                         requestedComments,
                         requestedSearches
                     }) => {
    const [modal, setModal] = useState(false);

    const toggle = () => {
        setIsProfileModalOpen(!isProfileModalOpen);
    };

    useEffect(() => {
        if (!isLoading) {
            fetchUserInfo();
        }
    }, [isLoading, isAuthenticated, user]);

    useEffect(() => {
        if (userInfo && isAuthenticated && !isLoading) {
            fetchUserActivity();
        }
    }, [isLoading, isAuthenticated, userInfo]);

    const fetchUserActivity = async () => {
        if (!userInfo || !userInfo.User) {
            console.error("User information is not available");
            return;
        }

        try {
            const email = userInfo.User;
            const searchesRequest = axios.get(process.env.REACT_APP_API_URL + `/getSearches`, { params: { email } });
            const commentsRequest = axios.get(process.env.REACT_APP_API_URL + `/getCommentsByUser`, { params: { email } });

            const [searchesResponse, commentsResponse] = await Promise.all([searchesRequest, commentsRequest]);

            // Update to use response data
            setRequestedSearches(searchesResponse.data);
            setRequestedComments(commentsResponse.data);
            const uniqueSearches = searchesResponse.data.filter((item, index, self) =>
                index === self.findIndex((t) => t.title === item.title)
            );
            const uniqueComments = commentsResponse.data.filter((item, index, self) =>
                index === self.findIndex((t) => t.title === item.title)
            );
            setRequestedSearches(uniqueSearches);
            setRequestedComments(uniqueComments);


            const updatedUserInfo = {
                ...userInfo,
                Searches: searchesResponse.data,
                Comments: commentsResponse.data
            };

            setUserInfo(updatedUserInfo);
        } catch (error) {
            console.error("Error fetching user activity:", error);
        }
    };

    const handleDeleteComment = async (title, user) => {
        try {
            await axios.delete(process.env.REACT_APP_API_URL + `/deleteComment/${title}`, { params: { user } });
            // Optionally refresh comments or update state to reflect deletion
        } catch (error) {
            console.error("Error deleting comment:", error);
        }
    };



    return (
        <div>
            <Modal isOpen={isProfileModalOpen} toggle={toggle}>
                <ModalHeader toggle={toggle}>Profile Information</ModalHeader>
                <ModalBody>
                    {userInfo ? (
                        <div>
                            {/* User info display */}
                            <p><strong>User:</strong> {userInfo.User}</p>
                            <p>
                                <strong>Darkmode:</strong>
                                <a style={{ color: "blue" }} onClick={handleDarkModeToggleSettings}>
                                    Toggle
                                </a>
                                : {isDarkMode ? "False" : "True"}
                            </p>

                            {/* Display Searches */}
                            <h6>{requestedSearches.length > 0 ? "Your Previous Searches" : "Your Previous Searches (None)"}</h6>
                            {requestedSearches.map((search, index) => (
                                <a key={index} href="#" onClick={() => {
                                    setIsSearchModalOpen(true);
                                    setSearchTerm(search.query);
                                    setSearchType("search");
                                }}>
                                    {search.query}
                                </a>
                            ))}

                            {/* Display Comments */}
                            <h6>{requestedComments.length > 0 ? "Your Old Comments" : "Your Old Comments (None)"}</h6>
                            {requestedComments.map((comment, index) => (
                                <p key={index}>{comment.title}</p>
                            ))}
                        </div>
                    ) : (
                        <p>Loading user info...</p>
                    )}
                </ModalBody>
                <ModalFooter>
                    <Button color="secondary" onClick={toggle}>Close</Button>
                </ModalFooter>
            </Modal>
        </div>
    );
};

export default ProfileInfo;
