import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Button } from "reactstrap";

const LogoutButton = ({ darkModeClass }) => {
    // const colorChoice = darkModeClass.replace(" text-bg-dark", "danger").replace(" text-bg-light", "danger");
    const { logout } = useAuth0();

    return (
        <Button color={"danger"} onClick={() => logout({ returnTo: window.location.origin })}>
            Log Out
        </Button>
    );
};

export default LogoutButton;
