// src/App.js
import React, { useState, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import NavbarComponent from "./components/NavbarComponent";
import HomePage from "./components/HomePage";
import ErrorModalMessage from "./components/ErrorModalMessage";

import { Auth0Provider } from "@auth0/auth0-react";

import "./App.css";
function App() {
    const { loginWithRedirect, getAccessTokenSilently, user } = useAuth0();
    const [userEmail, setUserEmail] = useState(null);

    const [data, setData] = useState([]); // State to hold search results
    const [apiEndpoint, setApiEndpoint] = useState("/topAll");
    const [passUpDarkMode, setPassUpDarkMode] = useState("text-bg-light"); // State to hold search results


    const choosePage = (endpoint) => {
        setApiEndpoint(endpoint);
    };

    return (
        <Auth0Provider
            domain={process.env.REACT_APP_DOMAIN}
            clientId={process.env.REACT_APP_CLIENTID}
            redirectUri={window.location.origin}
            cacheLocation="localstorage"
            useRefreshTokens
        >
            <div className={"App " + passUpDarkMode}>
                <HomePage
                    apiEndpoint={apiEndpoint}
                    setPassUpDarkMode={setPassUpDarkMode}
                    setApiEndpoint={setApiEndpoint}
                    userEmail={userEmail}
                />
            </div>
        </Auth0Provider>
    );
}

export default App;
