import React, { useState, useEffect } from 'react';
import { UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';

/**
 * Component to display a dropdown list of searches.
 * Allows the user to select a search and updates the API endpoint accordingly.
 *
 * @param {Function} setApiEndpoint - Function to set the API endpoint in the parent component.
 */
function SearchList({ setApiEndpoint }) {
    const [searches, setSearches] = useState([]);


    useEffect(() => {
        fetch(process.env.REACT_APP_API_URL + "/searches") // Adjust the URL as needed
            .then(response => response.json())
            .then(data => setSearches(data))
            .catch(error => console.error('Error fetching searches:', error));
    }, []);


    const handleSearchSelect = (key) => {
        // Updating the API endpoint based on selected search
        const newEndpoint = `/oldsearch/${key}`;
        console.log("handleSearchSelect updating endpoint to", newEndpoint);
        setApiEndpoint(newEndpoint);
    };

    return (
        <UncontrolledDropdown>
            <DropdownToggle caret color="primary">
                Searches
            </DropdownToggle>
            <DropdownMenu>
                {searches.map(search => (
                    <DropdownItem
                        key={search.key}
                        onClick={() => handleSearchSelect(search.key)}
                    >
                        {`Search #${search.key}`}
                    </DropdownItem>
                ))}
            </DropdownMenu>
        </UncontrolledDropdown>
    );
}

export default SearchList;