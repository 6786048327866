// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.navbar-dark-mode {
    color: white; /* Text color for dark mode */
}

.navbar-light-mode {
    color: red; /* Text color for light mode */

}

.navbar{
    border-bottom: 1px solid white;
    margin-bottom: 1%;



}`, "",{"version":3,"sources":["webpack://./src/components/NavbarComponent.css"],"names":[],"mappings":"AAAA;IACI,YAAY,EAAE,6BAA6B;AAC/C;;AAEA;IACI,UAAU,EAAE,8BAA8B;;AAE9C;;AAEA;IACI,8BAA8B;IAC9B,iBAAiB;;;;AAIrB","sourcesContent":[".navbar-dark-mode {\n    color: white; /* Text color for dark mode */\n}\n\n.navbar-light-mode {\n    color: red; /* Text color for light mode */\n\n}\n\n.navbar{\n    border-bottom: 1px solid white;\n    margin-bottom: 1%;\n\n\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
